/* @import url('https://unpkg.com/css.gg@2.0.0/icons/css/code-slash.css'); */
/* @import url('https://unpkg.com/css.gg@2.0.0/icons/css/mail.css'); */

:root {
  /* --main-color: #d98b42; */
  --main-color: #d6d5d2;
  /* --border-color:white; */
  --border-color: black;
  /* --saturated-color: rgba(0,0,0,0.6); */
  --select-color: white;
  --main-margin: 35px;
  --main-padding: 60px 30px;
  --coloor:linear-gradient(90deg, white, #bdb9ab);
 
}
.App {
  background-color: var(--main-color);
  /* background-image: var(--coloor); */
  /* color: black; */
  transition: all 0.4s ease-in-out;
}
.App.night-mode {
  background-color: black;
  color: #d6d5d2;
  transition: all 0.4s ease-in-out;
}

.night-mode {
  color: white;
  background-color: transparent;
  border-color: white;
}

.icon {
  width: 22px;
  height: 100%;
}

@font-face {
  font-family: 'poppins';
  src: url('../assets/font/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'bold-poppins';
  src: url('../assets/font/Poppins-Bold.ttf');
}

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: 'poppins';
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: var(--saturated-color);
}
header {
  /* color: black; */
  width: 100%;
  margin-bottom: var(--main-margin);
  /* background-image: linear-gradient(120deg, var(--main-color) , brown); */
  /* background-color: #d6d5d2; */
}
.container {
  display: flex;
  margin: 0 auto;
  padding: 0 15px;
  flex-direction: column;
  transition: all 0.4s linear;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 970px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (max-width: 428px) {
  .container {
      align-items: center;

  }
}

.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 10px;
}
.info {
  display: flex;
  margin: 20px 10px;
  align-items: center;
}

.info p {
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* *::selection,
 p i::selection {
  background-color: var(--main-color);
} */

.info p i {
  display: inline-block;
  margin: 0 10px;
}

.info img {
  margin: 0 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

header a {
  padding: 5px 5px;
  background-color: transparent;
  /* color: white; */
  text-decoration: none;
  transition: all 0.1s linear;
}
 
.nav {
  width: 100%;
}
.nav li {
  text-decoration: none;
  list-style-type: none;
  text-transform: uppercase;
} 
.nav a {
  width: 20%;
  padding: 5px 10px;
  margin: 0 auto;
  background-color: transparent;
  /* color: black; */
  text-decoration: none;
  transition: all 0.1s linear;
  font-size: 1rem;
  font-weight: 800;
} 

@media screen and (max-width: 326px) {
  header .container {
    padding: 10px;
  }
  .nav a {
    padding: 5px 8px;
  }
}

.nav a::selection {
  /* color: var(--main-color); */
}

header a:hover {
  color: var(--select-color);
  /* border: 1px solid var(--main-color); */
  transform: rotate(10deg);
  transform: scale(1.09);
}

.icons {
  display: flex;
  width: fit-content;
  align-items: center;
  margin: 10px 15px;
  /* background-color: var(--main-color); */
}
@media screen and (max-width: 425px) {
  .icons {
    margin: 5px 5px 20px;
  }
  .info {
    display: flex;
    margin: 20px 10px 0px ;
    align-items: center;
  }
}

header .icons a {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 0px 5px;
}
.icons i {
  /* color: black; */
  font-size: 1.4rem;
  transition: all 0.2s ease-in-out;
}

.icons i:hover {
  transform: rotate(10deg);
  transform: scale(1.09);
}

.container .small {
  width: 120px;
  padding: 3px;
  font-size: 14px;
}

.package {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
