#About {
  /* color: black; */
  border: 5px solid var(--border-color);
  margin-bottom: var(--main-margin);
  padding: var(--main-padding);
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
   /* box-shadow: var(--border-color) 5px 5px 1px 1px; */
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
   transition: all 0.4s linear;
 }
 #About:hover {
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
 }
 
#About .container {
  align-items: center;
}
.Heading {
  font-size: 4rem;
  font-weight: 800;
  border-bottom: 3px solid;
  /* color: black; */
  text-align: center;
  
}

@media screen and (max-width: 425px) {
  .Heading {
    font-size: 3rem;
  }
}

#About .Heading {
  width: auto;
}

#About .box {
  display: flex;
  
}
.stack {
  margin: 30px 15px 30px 0;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stack .icon {
  width: 35px;
}
#About i {
  margin: 1px 25px;
  font-size: 2.3rem;
}


.tech-icons i img {
transition: all 0.2s linear;
}
.tech-icons i img:hover {
  transform: translateY(-10px);
}

i.fa-html5 {
  color: #f59714;
}
i.fa-css3-alt {
  color: #74c0fc;
}
i.fa-js {
  color: yellow;
  position: relative;
}

i.fa-js::after {
  content: '';
  background-color: black;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 60%;
  left: 0;
  bottom: 6%;
}
i.fa-react {
  color: #74c0fc;
}

#About .my-photo {
  width: 20%;
  height: 70%;
  display: flex;
  border: solid 3px var(--border-color);
}

#About .my-photo img {
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 0%;
}

#About .About-me-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 50px 5px;
  font-size: 1.3rem;
  text-align: center;
}
.About-me-content .section:first-child {
  width: 70%;
}

#About p {
  width: 100%;
  /* color: black; */
  font-size: 1.2rem;
}

/* flag */

.boldy {
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.5rem;
  padding: 0 0 15px;
  /* line-height: 1; */
}
.flag {
  margin-left: 5px;
}
.cv-btn {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-self: flex-start; */
}
@media screen and (max-width: 768px) {
  #About {
    padding: 80px 15px;
  }

  .box .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #About .About-me-content {
    display: flex;
    flex-direction: column-reverse;
  }
  .About-me-content .section:first-child {
    width: 70%;
  }
  #About .section {
    width: 100%;
  }
  #About p {
    width: 100%;
    /* color: black; */
    font-size: 1.2rem;
    text-align: center;
  }
  .boldy {
    font-weight: 800;
    /* display: block; */
    font-size: 1.5rem;
    padding: 0 0 15px;
  }
  #About .my-photo {
    display: none;
  }

  .cv-btn {
    width: 25%;
    min-width: 100px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
  }
}

@media screen and (max-width: 426px) {
  .stack {
    margin: 20px 10px 20px 0;
    font-size: 1.1rem;
  }
  .tech-icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
  }
  #About i {
    margin: 1px 15px;
    font-size: 2rem;
  }
  .cv-btn {
    min-width: 120px;
  }
}
