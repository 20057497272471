body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.nighty {
  margin-top: 15px;
  position: relative;
}
.nighty-holder {
  position: relative;
  width: 25%;
  display: flex;
  justify-content: space-between;
}

.nighty-holder button::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 25px;
  background-color: var(--border-color);
  top: -25px;
  left: 30%;
}

.nighty-holder button::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 25px;
  background-color: var(--border-color);
  top: -25px;
  left: 70%;
}
