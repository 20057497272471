* {
  padding: 0;
  margin-right: 0;
  color: var(--saturated-color);
}

.landing,
.box-section {
  height: auto;
  width: 75%;
  padding: var(--main-padding);
  margin-bottom: var(--main-margin);
  display: flex;
  border: 5px solid var(--border-color);
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* background-color: transparent; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.4s linear;
}
.box-section:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.overlay {
  width: 100%;
  height: 100%;
  border-radius: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.landing .container-landing {
  position: relative;
  /* color: var(--secondry-color); */
  margin: 20px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .container-landing {
    width: 750px;
  }
}

@media (min-width: 970px) {
  .container-landing {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container-landing {
    width: 1170px;
  }
}

.Text {
  font-size: 2.36rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
}

*::selection {
  /* color: transparent; */
  /* background-color: var(--main-color); */
  background-color: var(--select-color);
}

.Text .title {
  font-size: 1rem;
  margin: 20px 0;
}
.job {
  font-size: 1.5rem;
}

/* .my-Work-btn, */
.btn {
  width: fit-content;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  background-color: transparent;
  border: var(--border-color) solid 3px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: var(--border-color) 5px 5px 1px 1px;
  /* overflow: hidden; */
  transition: all 0.2s linear;
}
/* .btn::before {
  content: '';
  background-color: #d6d5d2;
  border: 1px solid var(--border-color);
  width: 25px;
  height: 150px;
  position: absolute;
  left: -60px;
  top: -50px;
  transform: rotate(45deg);
  transition: all 0.6s ease;
} */
.btn:hover::before {
  /* left: 115%; */
}
.btn:hover {
  box-shadow: var(--border-color) 0px 0px 1px 1px;
}

.photo-handler {
  min-width: 250px;
  height: 300px;
  border-radius: 0%;
  overflow: hidden;
  border: 10px var(--border-color);
  border-style: double;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.photo-handler::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(214, 213, 210, 0.05);
}

.photo-handler img {
  width: 100%;
  height: 100%;
  border-radius: 0;
  object-position: center;
  object-fit: cover;
}

.more-btn a:hover span {
  background-color: var(--border-color);
}

@media screen and (max-width: 768px) {
  .landing,
  .box-section {
    padding: 80px 15px;
    flex-direction: column-reverse;
  }
  .landing .container-landing {
    /* color: var(--secondry-color); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Text {
    width: 100%;
    text-align: center;
  }
  .Text .name {
    text-align: center;
    font-size: 1.6rem;
  }

  .Text .job {
    font-size: 1.2rem;
  }

  .photo-handler {
    min-width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .photo-handler img {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
