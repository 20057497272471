.contact {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Heading {
  margin-bottom: var(--main-margin);
}
.quote {
  font-size: 1.3rem;
  font-weight: 500;
}
#arrow {
  font-size: 3rem;
}
@keyframes together {
  0% {
    left: -50px;
  }
  25% {
    left: 0;
  }
  50% {
    left: 50%;
  }
  75% {
    left: 75%;
  }
  100% {
    left: 110%;
  }
}
#together {
  font-size: 1.4rem;
  margin-bottom: 20px;
  padding: 5px;
  border: 2px solid;
  position: relative;
  box-shadow: var(--border-color) 5px 5px 1px 1px;
  overflow: hidden;
  text-align: center;
  transition: all 0.2s linear;
}

#together:hover {
  box-shadow: var(--border-color) 0px 0px 1px 1px;
}

/* #together::before {
  content: '';
  background-color: #d6d5d2;
  border: 1px solid;
  width: 25px;
  height: 150px;
  position: absolute;
  left: -60px;
  top: -50px;
  transform: rotate(45deg);
  transition: all 0.6s linear;
} */

/* #together:hover::before {
  left: 115%;
} */

.contact-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
form {
  display: flex;
  width: 50%;
  flex-direction: column;
  padding: 20px;
  border: solid 3px var(--border-color);
  /* border-radius: 10px; */
}
form label {
  font-size: 1.2rem;
}
form input {
  padding: 10px;
  margin: 10px 0;
  background-color: transparent;
  /* border-radius: 10px; */
  border: solid 3px var(--border-color);
  position: relative;
}

form textarea {
  padding: 10px;
  margin: 10px 0;
  min-height: 50px;
  overflow-y: hidden;
  background-color: transparent;
  /* border-radius: 10px; */
  border: solid 3px var(--border-color);
}

form textarea::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 102px;
  height: 10px;
  background-color: blue;
}

form .btn {
  color: var(--saturated-color);
  align-self: center;
}

@media screen and (max-width: 768px) {
  .quote {
    text-align: center;
  }
  #arrow {
    font-size: 3rem;
    margin-bottom: 10px;
  }

  .contact-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  form {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 12px;
    border: solid 3px var(--border-color);
    border-radius: 10px;
  }
  form label {
    font-size: 1.2rem;
  }
  form input {
    position: relative;
    padding: 10px;
    margin: 10px 0;
    background-color: transparent;
    border-radius: 10px;
    border: solid 3px var(--border-color);
  }

  form textarea {
    padding: 10px;
    margin: 10px 0;
    min-height: 50px;
    overflow-y: hidden;
    background-color: transparent;
    border-radius: 10px;
    border: solid 3px var(--border-color);
  }
}
.loader {
  width: 50px;
  color: var(--border-color);
  border: var(--border-color) solid 5px;
}
