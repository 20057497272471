.footer {
  width: 100%;
  padding: 10px;
  border-top: 5px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  /* background-color: #c6c5c2; */
  font-size: 1rem;
  font-weight: 900;
}

.personal-data {
  width: 70%;
  padding: 20px 0;
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.personal-data > div {
  margin: 0;
  /* border: 4px solid; */
  /* padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.bold {
  font-weight: bold;
  margin: 15px 10px;
  display: inline-block;
}

h4 {
  font-size: 1.5rem;
}

.footer a {
  text-decoration: none;
  /* color: black; */
  transition: all 0.1s linear;
}

.footer a:hover {
  /* color: #34495e; */
  font-size: 1.02rem;
}
@media screen and (max-width: 510px) {
  .footer {
    border: none;
  }

  .personal-data {
    width: 100%;
    justify-content: center;
  }
  .bold {
    margin: 0;
  }
  @media screen and (max-width: 320px) {
    .footer a {
      font-size: 0.8rem;
    }
  }
}

.footer .icons a {
  margin: 10px;
  transition: all 0.2s linear;
}

.footer .icons a:hover {
  transform: translateY(-10px);
}

.phone {
  margin: 0 10px;
}
